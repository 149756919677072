<template>
  <SocialAnalytics :uuid="uuid" platform="spotify" />
  <SocialAnalytics :uuid="uuid" platform="youtube" />
</template>
<script>
import { useRoute } from "vue-router";
import SocialAnalytics from "../components/SocialAnalytics";
export default {
  components: { SocialAnalytics },
  setup() {
    const route = useRoute();
    const uuid = route.params.uuid;

    return { uuid };
  },
};
</script>
<style lang="scss" scoped></style>
