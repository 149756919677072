<template>
  <!--begin::Charts Widget 3-->
  <div v-loading="loading" class="card mb-5 mb-xl-10 social-analytics">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <div>
        <h2 class="heading">
          {{ platform === "youtube" ? "Youtube Views" : "Spotify Listeners" }}
        </h2>
        <p class="subheading">
          {{
            platform === "youtube"
              ? "YouTube daily views over time"
              : "Daily evolution of Spotify monthly listeners"
          }}
        </p>
      </div>
      <!--begin::Toolbar-->
      <div class="card-toolbar" data-kt-buttons="true">
        <a
          type="button"
          :class="[{ active: days === 7 }]"
          class="
            btn btn-sm btn-color-muted btn-active btn-active-primary
            px-4
            me-1
          "
          @click="getArtistAnalyticsData(7)"
          >Last 7 days</a
        >

        <a
          :class="[{ active: days === 14 }]"
          class="
            btn btn-sm btn-color-muted btn-active btn-active-primary
            px-4
            me-1
          "
          @click="getArtistAnalyticsData(14)"
          >Last 14 days</a
        >

        <a
          :class="[{ active: days === 28 }]"
          class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4"
          @click="getArtistAnalyticsData(28)"
          >Last 28 days</a
        >
        <a
          :class="[{ active: days === 365 }]"
          @click="getArtistAnalyticsData(365, true)"
          class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4"
          >Last 12 Months</a
        >
      </div>
      <!--end::Toolbar-->
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Chart-->
      <apexchart
        height="200%"
        ref="socialAnalyticChart"
        type="area"
        :options="options"
        :series="series"
      ></apexchart>
      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Charts Widget 3-->
</template>

<script>
import { defineComponent, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { getArtistAnalytics } from "@/api/artists.api";
import { platforms } from "@/assets/static/platforms";

export default defineComponent({
  name: "SocialAnalytics",
  data() {
    return {
      loading: false,
      chartData: {},
      days: 365,
    };
  },
  props: {
    platform: {
      type: String,
      required: true,
    },
    uuid: {
      type: String,
      default: null,
    },
  },
  components: {},
  setup(props) {
    // API Call to get analytics data
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = platforms[props.platform].color;

    const options = ref({
      chart: {
        fontFamily: "inherit",
        type: "area",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.8,
          opacityTo: 0.3,
        },
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors: [baseColor],
      },
      xaxis: {
        categories: ["1", "2"],
        tickPlacement: "between",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: true,
          showDuplicates: false,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        crosshairs: {
          position: "front",
          stroke: {
            color: baseColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
          formatter: (value) => {
            return Number(value).toLocaleString();
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return Number(val).toLocaleString();
          },
        },
      },
      colors: [baseColor],
      grid: {
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeColor: baseColor,
        strokeWidth: 3,
      },
    });

    const series = ref([
      {
        name: "Listeners",
        data: [],
      },
    ]);

    async function getArtistAnalyticsData(noOfDays, getYearsData = false) {
      this.days = noOfDays;
      this.loading = true;
      const url =
        props.platform === "spotify" ? "spotify/listeners" : "youtube/views";
      const { data } = await getArtistAnalytics(
        props.uuid,
        noOfDays,
        getYearsData,
        url
      );
      this.series[0].name =
        props.platform === "spotify" ? "Listeners" : "Views";
      this.series[0].data = data.yAxis;
      this.options.xaxis.categories = data.xAxis;
      if (this.$refs.socialAnalyticChart) {
        this.$refs.socialAnalyticChart.updateOptions(this.options);
      }
      this.loading = false;
    }

    return {
      options,
      series,
      getArtistAnalyticsData,
    };
  },
  watch: {
    async uuid() {
      await this.getArtistAnalyticsData(this.days, true);
    },
  },
  async mounted() {
    if (this.uuid && this.uuid !== "") {
      await this.getArtistAnalyticsData(this.days, true);
    }
  },
});
</script>

<style lang="scss" scoped>
.social-analytics {
  .icon {
    height: 50px;
    width: 50px;
  }
}
</style>
