import httpClient from "./httpClient";

const ARTIST = "/artist";
const PLATFORM = "/platform";
const searchArtist = (searchQuery) =>
  httpClient.get(ARTIST, { params: { term: searchQuery } });

const getArtistByUUID = (uuid) => httpClient.get(`${ARTIST}/${uuid}`);

const getArtistUUIDBySpotifyPayload = (payload) =>
  httpClient.post(`${ARTIST}/soundchart-uuid`, payload);

const getArtistSocialInfoByPlatform = (uuid, platform, date) =>
  httpClient.get(`${PLATFORM}/followers`, {
    params: { uuid: uuid, platform: platform, date: date },
  });

const getArtistAnalytics = (uuid, noOfDays, getYearData, url) =>
  httpClient.get(`${PLATFORM}/apex-chart/${url}`, {
    params: { uuid: uuid, days: noOfDays, isYearData: getYearData },
  });

const getAllPlatformFanDistribution = (uuid) =>
  httpClient.get(`${PLATFORM}/followers/fanbase-distribution`, {
    params: { uuid: uuid },
  });

const getFanbaseGrowth = (uuid, platform, days, isYearData = false) =>
  httpClient.get(`${PLATFORM}/followers/${platform}/growth`, {
    params: { uuid, days, isYearData },
  });

const getSpotifyPopularity = (uuid, platform, days, isYearData = false) =>
  httpClient.get(`${PLATFORM}/apex-chart/spotify/popularity`, {
    params: { uuid, days, isYearData },
  });

const getSpotifyListenersByCountry = (uuid, days, isYearData = false) =>
  httpClient.get(`${PLATFORM}/spotify/countries-data`, {
    params: { uuid, days, isYearData },
  });

const getSimilarArtist = (uuid) => httpClient.get(`${ARTIST}/${uuid}/similar`);

const getTotalPlaylistOnSpotify = (uuid) =>
  httpClient.get(`${ARTIST}/${uuid}/playlist/spotify`);

const getSpotifyTopTenTracks = (uuid) =>
  httpClient.get(`${PLATFORM}/tracks/spotify/${uuid}`);

const getPaginatedArtistPlaylist = (
  uuid,
  platform,
  offset,
  limit,
  sortBy,
  sortOrder
) =>
  httpClient.get(
    `${ARTIST}/${uuid}/playlist/current/${platform}?offset=${offset}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`
  );

const getPlaylistOfArtists = (uuid, platform, offset, limit) =>
  httpClient.get(
    `${PLATFORM}/playlists/${platform}/${uuid}?offset=${offset}&limit=${limit}`
  );

const getFanbaseOvertime = (uuid, platform) =>
  httpClient.get(
    `${PLATFORM}/followers/fanbase-overtime?uuid=${uuid}&platform=${platform}`
  );

const scrapArtist = (name, uuid) =>
  httpClient.get(`save/artist-google?name=${name}&uuid=${uuid}`);

export {
  searchArtist,
  getArtistUUIDBySpotifyPayload,
  getArtistByUUID,
  getArtistSocialInfoByPlatform,
  getArtistAnalytics,
  getAllPlatformFanDistribution,
  getFanbaseGrowth,
  getSpotifyListenersByCountry,
  getSimilarArtist,
  getSpotifyTopTenTracks,
  getSpotifyPopularity,
  getPaginatedArtistPlaylist,
  getFanbaseOvertime,
  getPlaylistOfArtists,
  getTotalPlaylistOnSpotify,
  scrapArtist,
};
